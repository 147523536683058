import { ArrowBack as CancelIcon, Save as SaveIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import type { EventSeries } from 'core';
import type { FormikProps } from 'formik';
import type { FC } from 'react';
import { FormikTextField } from '../../../components/formik-fields/text-field.js';

interface Props {
  formik: FormikProps<EventSeries.Patch>;
}

export const EditDetailsView: FC<Props> = ({ formik }) => (
  <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Update Event Series Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormikTextField
              id="title"
              label="Event Series Title"
              formik={formik}
              fullWidth
              autoFocus
            />
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <LoadingButton
          color="primary"
          loading={formik.isSubmitting}
          onClick={formik.handleReset}
          startIcon={<CancelIcon />}
          variant="outlined"
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          color="primary"
          loading={formik.isSubmitting}
          startIcon={<SaveIcon />}
          type="submit"
          variant="contained"
        >
          Save
        </LoadingButton>
      </CardActions>
    </Card>
  </form>
);
