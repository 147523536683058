import { Box, Button, Collapse, Grid, Stack, Typography } from '@mui/material';
import type { Event, EventSeries, PaginatedList, Person } from 'core';
import type { FC } from 'react';
import { NotPublicChip } from '../../components/chips/not-public.js';
import { NotPublishedChip } from '../../components/chips/not-published.js';
import { GroupEntity } from '../../components/entity/group.js';
import { PageTitle } from '../../components/page-title/page-title.js';
import type { UseAxiosResult } from '../../hooks/use-axios.js';
import { EditDetails } from './edit/controller.js';
import { EventSeriesEventsView } from './events.js';

interface Props {
  edit: boolean;
  events: UseAxiosResult<PaginatedList<Event.WithCourses> | undefined>;
  data: EventSeries.Type;
  onEditClick: () => void;
  person: Person.Type;
  refresh: () => void;
}

export const EventSeriesDetailsView: FC<Props> = ({
  edit,
  events,
  data,
  onEditClick,
  person,
  refresh,
}) => (
  <PageTitle title={data.title}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Collapse in={!edit}>
          <Stack spacing={1}>
            <Box display="flex" flex={1} justifyContent="space-between">
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h2">{data.title}</Typography>
                {!data.public ? (
                  data.groupIds ? (
                    data.groupIds.map((groupId) => (
                      <GroupEntity
                        key={groupId}
                        groupId={groupId}
                        link={true}
                      />
                    ))
                  ) : (
                    <NotPublicChip />
                  )
                ) : undefined}
                {!data.published ? <NotPublishedChip /> : undefined}
              </Stack>
              {data.admin?.personIds?.includes(person._id) ? (
                <Button onClick={onEditClick} variant="outlined">
                  Edit
                </Button>
              ) : undefined}
            </Box>
          </Stack>
        </Collapse>
        <Collapse in={edit}>
          <EditDetails id={data._id} data={data} refresh={refresh} />
        </Collapse>
      </Grid>
      <Grid item xs={12} spacing={3}>
        <EventSeriesEventsView events={events} person={person} />
      </Grid>
    </Grid>
  </PageTitle>
);
