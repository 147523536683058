import { Container, Skeleton } from '@mui/material';
import type { Event, EventSeries, PaginatedList, Person } from 'core';
import type { FC } from 'react';
import { ErrorView } from '../../components/common/error.js';
import type { UseAxiosResult } from '../../hooks/use-axios.js';
import { NotFoundPage } from '../not-found/page.js';
import { EventSeriesDetailsView } from './event-series-details.js';

interface Props {
  edit: boolean;
  events: UseAxiosResult<PaginatedList<Event.WithCourses> | undefined>;
  eventSeries: UseAxiosResult<EventSeries.Type | undefined>;
  onEditClick: () => void;
  person: Person.Type;
  refresh: () => void;
}

export const EventSeriesPageView: FC<Props> = ({
  edit,
  events,
  eventSeries: { data, error, loading },
  onEditClick,
  person,
  refresh,
}) => (
  <Container maxWidth="md" sx={{ my: 3 }}>
    {loading ? (
      <Skeleton variant="rounded" height={60} width="100%" />
    ) : error ? (
      <ErrorView error={error} />
    ) : !data ? (
      <NotFoundPage />
    ) : (
      <EventSeriesDetailsView
        data={data}
        events={events}
        edit={edit}
        onEditClick={onEditClick}
        person={person}
        refresh={refresh}
      />
    )}
  </Container>
);
