import type { Event, EventSeries, PaginatedList } from 'core';
import type { FC } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import uri from 'uri-tag';
import { useAuthenticated } from '../../auth/auth-provider.js';
import { useAxios } from '../../hooks/use-axios.js';
import { EventSeriesPageView } from './view.js';

const Controller: FC = () => {
  const { person } = useAuthenticated();

  const [edit, setEdit] = useState(false);
  const { countryOrYear, slug } = useParams();

  const [eventSeries, refresh] = useAxios<EventSeries.Type>(
    countryOrYear && slug && uri`/api/v1/event-series/${countryOrYear}/${slug}`,
  );
  const [events] = useAxios<PaginatedList<Event.WithCourses>>(
    eventSeries.data &&
      uri`/api/v1/event-series/${eventSeries.data._id}/events?sort=${
        eventSeries.data.sortDescending ? '-start' : 'start'
      }`,
  );

  return EventSeriesPageView({
    edit,
    events,
    eventSeries,
    onEditClick: () => setEdit(true),
    person,
    refresh: () => {
      setEdit(false);
      refresh();
    },
  });
};

export default Controller;
