import type { Event, PaginatedList, Person } from 'core';
import type { FC } from 'react';
import { ErrorView } from '../../components/common/error.js';
import { ListPendingView } from '../../components/common/list-pending.js';
import type { UseAxiosResult } from '../../hooks/use-axios.js';
import { ListItemsView } from '../event-list/list-items.js';
import { NotFoundPage } from '../not-found/page.js';

interface Props {
  events: UseAxiosResult<PaginatedList<Event.WithCourses> | undefined>;
  person: Person.Type;
}

export const EventSeriesEventsView: FC<Props> = ({
  events: { data, error, loading },
  person,
}) =>
  loading ? (
    <ListPendingView />
  ) : error ? (
    <ErrorView error={error} />
  ) : !data ? (
    <NotFoundPage />
  ) : (
    <ListItemsView
      data={data}
      hideSeries={true}
      person={person}
      showGroup={false}
    />
  );
