import { captureException } from '@sentry/react';
import axios from 'axios';
import { EventSeries } from 'core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import uri from 'uri-tag';
import { useCache } from '../../../providers/cache.js';
import type { ExistingEntityViewProps } from '../../../utils/types.js';
import { formikCheck } from '../../../utils/validation.js';
import { EditDetailsView } from './edit-details.js';

export const EditDetails: FC<ExistingEntityViewProps<EventSeries.Type>> = ({
  id,
  data,
  refresh,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { group } = useCache();

  const formik = useFormik<EventSeries.Patch>({
    initialValues: {
      title: data.title,
      organizationIds: data.organizationIds ?? [],
      groupIds: data.groupIds ?? [],
      public: data.public,
      published: data.published,
      tags: data.tags ?? [],
      labels: data.labels ?? {},
    },
    validate: async (value) => formikCheck(EventSeries.patchContract, value),
    validateOnBlur: true,
    onReset: refresh,
    onSubmit: async (body) => {
      try {
        await axios.patch(uri`/api/v1/event-series/${id}`, body);
        enqueueSnackbar('Event series updated', { variant: 'success' });
      } catch (err: unknown) {
        captureException(err);
        if (err instanceof Error) {
          enqueueSnackbar(`Unable to update: ${err.message}`, {
            variant: 'error',
          });
        } else {
          throw err;
        }
      }
      group.invalidate(id);
      refresh();
    },
  });

  return EditDetailsView({
    formik,
  });
};
